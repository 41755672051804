body {
  max-width: 100%;
  padding: 20px;
  padding-top: 0px;
  font-family: 'PT Sans', sans-serif;
  background: linear-gradient(to right, #31669b 50%, #254d75 50%);
  color: #fff;
}

h1 {
  font-size: 50px;
}
