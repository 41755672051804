.blackout-logo img {
  width: 300px;
}

.blackout-mobile-message {
  font-size: 5em;
  margin-top: 35vh;
}

.blackout-mobile-message i {
  text-align: center;
  margin: 0px auto;
}

.blackout-clock .ClockWrapper {
  background: none;
  margin: 0px auto;
}

.blackout-clock .ClockWrapper .Clock {
  color: white;
  font-size: 10em;
}
