.SingleView .ClockWrapper {
  margin-top: 10px;
}
.SingleView .Clock {
  font-size: 4.9em;
}

.SingleView .AdditionalMessageWrapper {
  font-size: 3em;
}

.SingleView .PrayerTimeSingleViewWrapper {
  width: 100%;
}

.SingleView .PrayerTimesSingleView {
  float: right;
  width: 85%;
  padding-right: 50px;
}

.SingleView .PrayerTimesSingleView td {
  padding-bottom: 20px;
}

.SingleView .SunriseAndZawwalWrapper,
.SingleView .JummahTimesWrapper {
  margin-top: 30px;
}

.SingleView .PrayerTimesSingleView,
.SingleView .SunriseAndZawwalWrapper,
.SingleView .JummahTimesWrapper {
  font-size: 3.5em;
}

@media only screen and (max-width: 600px) {
  .SingleView .AdditionalMessageWrapper,
  .SingleView .SunriseAndZawwalWrapper,
  .SingleView .PrayerTimesSingleView,
  .SingleView .JummahTimesWrapper,
  .SingleView .DateWrapper {
    font-size: 1em;
  }

  .SingleView .Clock {
    font-size: 3em;
  }

  .SingleView .PrayerTimesSingleView {
    float: none;
    padding: 0;
    width: 100%;
  }

  .SingleView .DateWrapper {
    margin-top: 10px;
  }

  .SingleView .DateWrapper,
  .SingleView .DateWrapper .row {
    text-align: center;
    display: block;
    width: 100%;
  }
}
