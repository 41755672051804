.SunriseAndZawwalWrapper {
  margin-top: 70px;
  width: 100%;
  font-size: 3em;
}

.SunriseAndZawwalTable {
  float: right;
  padding-right: 50px;
}

.SunriseAndZawwalTable td {
  min-width: 150px;
}

.SunriseAndZawwalTable th,
.SunriseAndZawwalTable td {
  text-align: right;
  padding-left: 115px;
}

@media only screen and (max-width: 600px) {
  .SunriseAndZawwalWrapper {
    font-size: 1em;
  }

  .SunriseAndZawwalTable th,
  .SunriseAndZawwalTable td {
    padding-left: 0;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .SunriseAndZawwalWrapper {
    font-size: 2em;
  }

  .SunriseAndZawwalTable th,
  .SunriseAndZawwalTable td {
    padding-left: 100px;
  }
}
