body {
  background: #fff;
}

.WidgetWrapper .PrayerTimes {
  color: #0b0c0c;
  font-size: 16px;
}

.WidgetWrapper .PrayerTimes {
  padding-top: 25px;
}
