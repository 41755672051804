.PrayerTimesSingleViewWrapper {
  width: 100%;
}

.PrayerTimesSingleView {
  font-size: 3em;
  text-align: right;
  color: white;
  margin-left: 60px;
  padding-top: 50px;
  width: 840px;
}

.PrayerTimesSingleView th {
  font-weight: bolder;
  font-size: 1em;
  text-align: right;
}

.PrayerTimesSingleView th:last-child {
  padding-left: 0px;
}

.PrayerTimesSingleView td,
.PrayerTimesSingleView th {
  margin: 150px;
  padding-bottom: 10px;
}

.PrayerTimesSingleView th:first-child {
  font-size: 1.2em;
  text-align: left;
}

.PrayerTimesSingleView .mithl-text {
  font-size: 0.5em;
  max-width: 100px;
}

.PrayerTimesSingleView td {
  min-width: 100px;
  font-size: 1.3em;
}

.PrayerTimesSingleView td:nth-child(3) {
  font-weight: bolder;
}

.PrayerTimesSingleView td:last-child.normal-text {
  font-weight: normal;
}

.PrayerTimesSingleView .bullet-list--no-decorations {
  list-style-type: none;
  font-size: 0.7em;
  margin: 0;
}

@media only screen and (max-width: 600px) {
  .PrayerTimesSingleView {
    font-size: 1em;
    margin-left: 0;
    width: 100%;
  }

  .PrayerTimesSingleView th:last-child {
    padding-left: 0;
  }

  .PrayerTimesSingleView td {
    min-width: 10px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .PrayerTimesSingleView {
    font-size: 2em;
    margin-left: 0;
    width: 100%;
    padding: 30px;
  }

  .PrayerTimesSingleView th:last-child {
    padding-left: 0;
  }

  .PrayerTimesSingleView td {
    min-width: 10px;
  }
}

.PrayerTimesSingleView .nextJammahHighlight {
  font-weight: bolder;
  color: #ff3995;
}
