.body_GoogleSlides {
  padding: 0px;
  margin: 0px;
  height: 100%;
  background: linear-gradient(to right, #31669b 50%, #254d75 50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-over-slides {
  max-width: 100%;
  padding: 20px;
  padding-top: 0px;
}

.clock-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
