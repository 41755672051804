.HadithOfTheDayWrapper {
  max-width: 70%;
  text-align: center;
  background-color: #fff;
  left: 30%;
  margin: 0 auto;
  font-size: 4em;
  margin-top: 100px;
  border-radius: 5%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.Hadith {
  font-size: 30px; /* Reduced font size */
  text-align: justify;
  margin-top: 30px; /* Reduced margin */
  margin: 20px;
}

.HadithSource {
  font-size: 18px; /* Reduced font size */
  margin: inherit;
  font-weight: bold;
  font-size: 0.4em;
}

@media only screen and (max-width: 991px) {
  .HadithOfTheDayWrapper {
    width: 90%;
    margin-top: 10px;
    padding: 10px;
    font-size: 2em;
  }
}

@media only screen and (max-width: 600px) {
  .HadithOfTheDayWrapper {
    font-size: 2em;
    margin-top: 10px;
  }
  .HadithSource {
    font-size: 1em;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .HadithOfTheDayWrapper {
    font-size: 2em;
    margin-top: 10px;
  }
  .HadithSource {
    font-size: 1em;
  }
}
